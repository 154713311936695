import React, { useState, useEffect } from "react";
import API from "../../backend";
import axios from "axios";

const GetProducts = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
   
    const fetching = async () => {
      try {
        const response = await axios.get(`${API}/product`); //"http://127.0.0.1:8000/api/product
        if (response.status === 200) {
          // Check the status code for success
          setProducts(response.data);
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetching(); // Call the fetching function here
  }, []);
  return (
    <div>
      <div>Products</div>
      <ul>
        {products.length > 0 ? (
          products.map((item, index) => (
            <li key={item.id}>
              {item.name} | {item.id}
              <img
                src={item.image}
                alt={item.name}
                style={{
                  width: "100px",
                  height: "100px",
                  margin: "2px",
                  borderColor: "orange",
                  borderStyle: "solid",
                }}
              />
            </li>
          ))
        ) : (
          <li>No data</li>
        )}
      </ul>
    </div>
  );
};
//export default GetProducts;

const GetProduct = () => {
  return fetch(`${API}/product`, { method: "GET" })
    .then((res) => {
      console.log(res.json());
    })
    .catch((error) => console.log(error));
};

export default GetProduct;
