import React, { useEffect, useState } from "react";
import GetProduct from "./coreapicalls";
import axios from "axios";
import API from "../../backend";
import Card from "../Card";
import Base from "../Base";
import constants from "../../constants.json"
const Home = () => {
  const [Product, setProduct] = useState([]);
  const [error, setError] = useState(false);

  const getProds = async () => {
    try {
      const data = await axios.get(`${API}/product`);
      if (data.status === 200) {
        setProduct(data.data);
      } else {
        console.log(data.error);
        setError(data.error);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getProds();
  }, []);

  return (
    <Base title={constants.shopname} description={constants.description}>
      <div className="row">
        {/* {Product.length > 0 ? (
          Product.map((item, index) => (
            <div key={item.id} className="cols-4 mb-4">
              <h1>
                {item.id} {item.name}
              </h1>
              
            </div>
          ))
        ) : (
          <p>No Products found</p>
        )} */}
        {Product.map((product,index)=>{
          return (<div key={index} className="col-4 mb-4"> <Card product={product}/></div>)
        })}
      </div>
    </Base>
  );
};

export default Home;
