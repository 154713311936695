import React from 'react'

const ImageHelper = ({product}) => {
    const imageurl=product ? product.image :`https://imgmedia.lbb.in/media/2022/09/632846916e5fb64e9bd02f1a_1663583889764.jpg`

  return (
    <div className='rounded border border-success p-2'>
        <img src={imageurl} style={{maxHeight:"100%", maxWidth:"100%"}} className='mb-3 rounded'/>
    </div>
  )
}

export default ImageHelper;