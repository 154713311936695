import React from "react";




const addItemToCart = (item, next) => {
  let cart = [];
  
  if (typeof window != undefined) {
    if (localStorage.getItem("cart")) {
        cart=JSON.parse(localStorage.getItem("cart"));
    }
    const isIdPresent = cart.some(cartitem => cartitem.id === item.id);
    console.log(isIdPresent)
    if(!isIdPresent){
        cart.push({...item,});
        localStorage.setItem("cart",JSON.stringify(cart));
        console.log(cart)
        next();
    }else{
        
        alert("product alreday exists in cart: "+item.name)
    }
    
  }
};

export default addItemToCart;
