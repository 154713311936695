import React from "react";
import { BrowserRouter, Routes, Route, NavLink, } from "react-router-dom";

import GetProducts from "./core/helper/coreapicalls.js";
import Home2 from "./core/Home2.js";
import Home from "./core/helper/Home.js";
const Router = () => {
    return(
        <BrowserRouter>
        <Routes>
            <Route path="/"  element={<div>redirect to other pages <NavLink to={"/home"}>home</NavLink> <NavLink to={"/All"}>Products</NavLink> <NavLink to={"/home-hitesh"}>New Home</NavLink></div>}></Route>
            <Route path="/home"  element={<Home2></Home2>}></Route>
            <Route path="/All" element={<GetProducts/>}></Route>
            <Route path="/home-hitesh" element={<Home/>}></Route>
            <Route path="*" element={<div>Page not found ,redirect to other pages <NavLink to={"/home"}>home</NavLink> <NavLink to={"/All"}>Products</NavLink></div>}></Route>
        </Routes>
        </BrowserRouter>
    )
}
export default Router;