import { Navigate, json, redirect } from "react-router-dom";
import ImageHelper from "./helper/ImageHelper";
import addItemToCart from "./Cart";

const Card = ({ product, addtoCart = true, removeFromCart = false }) => {
  const productdescription = product ? product.description : product.name;
  const price = product.price;
  const category = product.category;
  const name = product.name;
  
  //check the user is authenticated or not to add cart
  const isAuthenticated = true;
  const addToCart = () => {
    if (isAuthenticated) {
        addItemToCart(product,()=>{});
      console.log("item Added to cart" +JSON.stringify(product));
    } else {
      console.log("Please Login");
    }
  };
  const getAredirect = (redirect) => {
    if (redirect) {
      <Navigate to="/cart" />;
    }
  };

  const showAddToCart = (addToCart) => {
    return (
      addToCart && (
        <button
          onClick={addToCart}
          className="btn btn-block btn-outline-success mt-2 mb-2"
          data-toggle="modal" data-target="#staticBackdrop">
          Add to Cart
        </button>
      )
    );
  };
  const showRemoveFromCart = (removeFromCart) => {
    return (
      removeFromCart && (
        <button
          //TODO
          onClick={() => {
            console.log("Product removed from cart");
          }}
          className="btn btn-block btn-outline-danger mt-2 mb-2"
        >
          Remove from cart
        </button>
      )
    );
  };


  return (
    <div className="card text-white bg-dark border border-info ">
      <div className="card-header lead font-weight-bold">{name}</div>
      <div className="card-body">
        <ImageHelper product={product}></ImageHelper>
        <p className="lead bg-success font-weight-normal text-wrap">
          {/* this photo looks great  */}
          {productdescription}
        </p>
        <p className="btn btn-success rounded  btn-sm px-4">INR {price}</p>
        <div className="row">
          <div className="col-12">{showAddToCart(addToCart)}</div>

          <div className="col-12"></div>
        </div>
      </div>
    </div>
  );
};
export default Card;
