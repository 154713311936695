import React from "react";
import constants from "../../src/constants.json";

const Base = ({
    title = "My Title",
    description = "My decription",
    className = "bg-dark text-white p-4",
    children,
}) => {
    return (
        <div>
            <div className="container-fluid">
                <div className="jumbotron bg-dark text-white text-center">
                    <h2 className="display-4"><img style={{height:"3rem",borderRadius:"10px" ,margin:"5px 5px 14px 2px",bottom:"10px "}} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8EsSWo8iRX1-wyDrZpleLkVS6Ar2Ox5xXevquEQk&s"></img>{title}</h2>
                    <p className="lead">{description}</p>
                    <h4>We are Coming soon Digitally..</h4>
                </div>
                <div className={className}>{children} </div>
            </div>
            <footer className="footer bg-dark mt-auto py-3">
                <div className="container-fluid bg-success text-white text-center py-3">
                    <h4>If you have any query feel free to reach us <p>{constants.owner}</p></h4>
                    <button className="btn btn-warning bt-lg">Contact Us</button>
                    <div className="container">
                        <span className="text-white">An Amazing jewellery store</span>
                        
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Base;
