import React, { useState, useEffect } from "react";
import API from "../backend";
import Base from "./Base";
import constants from "../constants.json";
// import axios from "axios";
// const api = axios.create("http://127.0.0.1:8000/api/");
import "../../src/index.css";
import axios from "axios";
const Home2 = () => {
  const [user, setUser] = useState([]);
  async function fetchdata() {
    try {
      const response = await fetch(`${API}/user`);
      const data = await response.json();
      setUser(data);
      //console.log(data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchdata();
  }, []);

 

  return (
    <Base title={constants.shopname} description={constants.description} >
      <div>Users data</div>
      <ul>
        {user.length > 0 ? (
          user.map((item, index) => (
            <li key={index}>
              {item.name} - {index}
            </li>
          ))
        ) : (
          <li>No data</li>
        )}
      </ul>
      
    </Base>
  );
};

export default Home2;
